.dashboard h1{
    margin-top: 50px ;
}
.dashboard_input{
    margin-top:2rem;
    padding:20px 0;
}
.dashboard_input_name,.dashboard_input_password{
    display:flex;
    gap:1rem;
    align-items: center;
}
.dashboard_input{

    display: grid;
    gap: 1.4rem;
    place-items: center;
}
.dashboard input{
    padding: 8px 12px;
    font-weight: 400;
}
.dashboard button{
    padding: 8px 24px;
    letter-spacing: 2px;
    font-size: 17px;
    background-color: #1a1a1a;
    color: white;
    text-transform: uppercase;
    border: 0;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    transition: .3s;

}
.dashboard button:hover{
    background-color: gray;
}
table{
    font-size:14px;
    max-width:1000px;
    margin:auto;
    line-height: 1.4rem;
}
.dashboard_table{
    margin:0 1rem 3rem;
}
table,tr,td,th{
    padding:1rem;
    border:1px solid #1a1a1a;
    border-collapse: collapse;
}
th:nth-child(3){
    width: 40%;
}