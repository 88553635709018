.navbar{
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    z-index: 1000;
    padding: 0 2rem;
    backdrop-filter: blur(5px);
    backface-visibility: hidden;
    transform: translateZ(0px);
    color:white;
}
.navbar_container{
    display: flex;
    align-items:center;
    justify-content: space-between;
}
.navbar_container_logo{
    width: 160px;
}
li{
    list-style: none;
}

.navbar_container_items ul li a{
    padding: 10px 0;
    position: relative;
    text-decoration: none;
}
.navbar_container_items ul li a::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    border-radius: 20px;
    height: 2px;
    background-color: white;
    transition: .5s;
}
.navbar_container_items ul li a:hover::after{
    width: 100%;
}
.navbar_container_items ul{
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar_container_items li{
    font-size: 16px;
    margin-left:2rem;
}
.navbar_container_items li a{
    text-transform: capitalize;
    color:inherit;
    padding: 5px 0;
}
.navbar_container_bars{
    cursor: pointer;
    padding: 0 5px;
    display: none;
}

@media screen and (max-width:900px){
    .navbar{
        padding: 0 1rem;
    }
    .navbar_container{
        position: relative;
    }
    .navbar_container_bars{
        display: block;
    }
    .navbar_container_logo{
        width: 140px;
    }
    .navbar_container_items {
        box-shadow: 1px 1px 20px rgba(37, 37, 37, 0.8);
        background:rgb(255, 255, 255,.95);
        color:rgba(13, 13, 13, .9) !important;
        border-radius: 10px;
        font-weight: 600;
        position: absolute;
        top:110%;
        right: -400px;
        width: 50%;
        margin: auto;
        opacity: 0;
        transition: right .8s ease,opacity .8s ease;
    }
    .navbar_container_items ul li a::after{
        background-color: rgba(13, 13, 13, .9);
    }
    .navbar_container_items.open{
        transition: .3s;
        opacity: 1;
        right:0;
    }
    .navbar_container_items ul{
        padding: 30px 0;
        flex-direction: column;
    }
    
    .navbar_container_items ul li{
        padding: 12px 0;
        margin-left: 0;
    }
}
@media screen and (max-width:600px){
    .navbar_container_items {
        width: 60%;
    }
}