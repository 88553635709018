.hero {
  position: relative;
  padding: 40px 10% 220px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, #1a1a1a, #2e2e2e);
}
.hero_container {
  padding-top: 80px;
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  height: 520px;
}
.hero_container_content{
    color: var(--white);
}
.hero_container h3{
    margin: .3rem 0 1.3rem;
}
.hero_container p {
  max-width: 500px;
  color: var(--white);
}
.hero img {
    box-shadow: 
    -10px -10px 3px rgba(255, 255, 255, 0.2),  
    10px 10px 3px rgba(255, 255, 255, 0.2); 
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
  .hero_btn {
    margin-top: 1.3rem;
    padding: 15px 30px;
    background-color: transparent;
    color: var(--white);
    border-radius: 25px;
    font-size: 18px;
    border: 2px solid var(--white);
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease; 
    cursor: pointer;
    display: inline-block;
}

.hero_btn:hover {
    background-color: rgba(255, 255, 255, 0.1); 
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
}
.hero {
  position: relative;
}
.hero_wave_img  {
  position: absolute;
  bottom: -1px;
  z-index: 100;
  left: 0;
}

@media screen and (max-width: 900px) {
  .hero {
    padding: 60px 5px 210px;
  }
  .hero_container {
    text-align: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .hero img {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 500px) {
  .hero {
    padding: 60px 5px 170px;
  }
}

/*  192.168.197.162 */
