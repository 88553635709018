.projects {
  background: var(--color-white-bg);
}

.projects_container {
  position: relative;
  z-index: 70;
  padding: 90px 0;
}

.projects_container p {
  color: var(--color-text);
}

.projects_container h1 {
  margin-bottom: 60px;
  color: var(--color-primary);
}

.projects_container_box_img_container {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.projects_container_project_img_content {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 15px;
  bottom: -100px;
  left: 0px;
  width: 100%;
  height: 80px;
  padding: 15px;
  color: var(--white);
  background-color: var(--color-primary);
  opacity: 0.95;
  overflow: hidden;
  transition: bottom 0.3s ease;
}

.projects_container_project_img_content h3 {
  font-size: 20px;
  margin-bottom: 6px;
  color: var(--white);
}

.projects_container_project_img_content h4 {
  font-size: 16px;
  font-weight: 400;
  gap: 0.5rem;
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.1);
}

.projects_container_boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.projects_container_box {
  overflow: hidden;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.8));
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.projects_container_box .projects_container_box_img {
  overflow: hidden;
  border-radius: 10px;
}

.projects_container_box_img_container:hover .projects_container_project_img_content {
  bottom: 0;
}

.projects_container_box img {
  border-radius: 10px;
  object-fit: cover;
  height: 240px;
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
}

.projects_container_box img:hover {
  opacity: 0.96;
  transform: scale(1.05);
}

.projects_container_box_text {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  padding: 1.5rem 0.9rem 0;
}

.projects_container_box_text_type {
  font-size: 12px;
}

.project_btn {
  gap: 10px;
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid var(--color-primary);
  font-weight: var(--fw-600);
}
.project_btn a{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .2rem;
}
.projects_container_box_description{
  width: 100%;
font-size: 1rem;
}

.project_btn:hover {
  border: 2px solid var(--color-primary);
  background: transparent;
  color: var(--color-primary);
}

@media screen and (max-width: 1000px) {
  .projects_container_box_text {
    padding: 0.7rem 0.5rem 0;
  }
}

@media screen and (max-width: 1100px) {
  .projects_container_boxes {
    grid-template-columns: 1fr 1fr;
  }
  .projects_image_box {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .projects_container_box_text h3 {
    font-size: 22px;
  }
  .projects_container_boxes {
    grid-template-columns: 1fr;
  }
  .projects_container_box {
    width: 75%;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .projects_container_box:not(:first-child) img {
    height: 210px;
  }
  .projects_container_box {
    width: 100%;
    margin: auto;
  }
}
