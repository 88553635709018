.shape_icons{
    background: rgba(255, 255, 255, .4);
}
.rounded_square{
    transform: rotate(50deg);
}
.all_shapes{
    opacity: .5;
    z-index: 1;
}
.shapes_container {
    width: 100%;
    height: 100%;
    overflow: hidden; 
}

.shape {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; 
    transition: top 0.2s ease; /* Smooth transition for movement */

}