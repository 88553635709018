.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--color-background);
    color: var(--color-primary);
    text-align: center;
    padding: 20px;
  }
  
  .not-found-image {
    max-width: 500px;
    object-fit: cover;
    height: 350px;
    object-position: top; 
    margin-bottom: 20px; 
  }
  
  .not-found-message {
    font-size: 1.5rem; 
    margin: 10px 0;
    color: var(--color-text);
  }
  
  .not-found-button {
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: var(--white);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .not-found-button:hover {
    background-color: var(--color-primary); 
  }

  @media screen and (max-width: 608px) {
    .not-found-image {
        max-width: 100%;
        height: auto; 
        margin-bottom: 20px; 
      }
      .not-found-message {
        font-size: 1.1rem; 
      }
  }